/* Navbar.css */
.navbar {
    display: flex;
    justify-content: center; /* Center the navbar content */
    flex-direction: column;
    align-items: center;
    background: linear-gradient(90deg, #121937, #1d3846, #001134); /* Darker Cyan/Violet Gradient */
    color: #ffffff;
    font-family: 'Poppins', sans-serif; /* Apply the Poppins font */
    position: relative; /* Allows absolute positioning for the dropdown */
}

.navbar-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center; /* Center the links */
    flex-grow: 1; /* Ensures the links take up available space for centering */
}

.navbar-link-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navbar-link {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Removes the highlight on mobile devices */
    user-select: none; /* Prevents text selection */
    color: #ffffff;
    text-decoration: none;
    padding: 15px 20px;
    font-size: 1.2rem;
    font-weight: 700;
    border-radius: 5px;
    transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.navbar-link:hover {
    background-color: rgba(255, 255, 255, 0.005); /* Slightly more intense background on hover */
    color: #ffffff; /* Cyan color on hover for contrast */
    transform: scale(1.4); /* Slight scaling effect on hover */
    text-decoration: underline;
}

.navbar-click-hint {
    font-size: 0.8rem; /* Smaller font size */
    opacity: 0.8; /* Slightly less prominent */
    animation: shake 1s infinite; /* Apply the shake animation */
    text-align: center; /* Ensure text is centered */
}

@media (max-width: 768px) {
    .navbar-link {
        padding: 10px 15px; /* Adjust for smaller screens */
        font-size: 1rem;
    }
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-1px); }
    50% { transform: translateX(1px); }
    75% { transform: translateX(-1px); }
    100% { transform: translateX(0); }
}