/* ContactDetail.css */
.contact-detail {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between logo and link */
}

.contact-logo {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.contact-link {
    font-size: 1rem;
    color: #00ffea; /* Cyan color for emphasis */
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-link:hover {
    color: #ffffff; /* Change color on hover */
}