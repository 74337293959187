/* OptionTile.css */
.option-tile {
    padding-bottom: 1rem;
    background-color: #162a34; /* Dark background color */
    color: #ffffff; /* White text */
    border-radius: 1rem; /* Rounded corners */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* Distribute space between elements */
    width: 100%; /* Full width of the grid cell */
    min-height: 16rem; /* Minimum height for the tile */
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5); /* Soft shadow for depth */
    text-align: center;
    max-width: 32rem;
}

.option-tile:hover {
    transform: scale(1.05); /* Slightly enlarge the tile on hover */
    transition: all 0.5s; /* Smooth transition for hover effects */
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.7); /* Increase shadow on hover for more depth */
}

.logo-container {
    background: linear-gradient(-45deg, #1847bd, #81fca2); /* Subtle Cyan/Violet Gradient */
    border-radius: 10px 10px 0px 0px;
    width: 100%; /* Adjusted size for the logo container */
    height: 10rem;
    margin-bottom: 1rem;
}

.logo {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure the logo is fully contained */
}

.option-headline {
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
    font-weight: 600;
    color: #00e5ff; /* Cyan color for emphasis */
}

.option-text {
    color: #b0c7d4;
    line-height: 1.5;
    font-size: 1rem;
    margin-top: auto; /* Push the text down to fill the space */
    margin: 1rem;
}

.option-text p {
    margin: 0;
    line-height: 1.8;
}