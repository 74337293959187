/* BwHeadline.css */

/* Container for the headline */
.bw-text-container {
  background-color: rgb(0, 0, 0); /* Black background */
  padding: 20px; /* Add some padding */
  border-radius: 8px; /* Optional: Rounds the corners */
  text-align: center; /* Center-align the text */
}

/* Headline text styling */
.bw-headline {
  color: rgb(255, 255, 255); /* White text color */
  font-size: 2rem; /* Larger font size */
  font-weight: bold; /* Bold text */
  margin: 0; /* Remove default margin */
  text-transform: uppercase; /* Optional: Uppercase text */
  letter-spacing: 1px; /* Optional: Space between letters */
}