/* ProsAndCons.css */
.pros-and-cons {
    display: flex;
    justify-content: space-between;
    gap: 2rem; /* Space between columns */
    padding: 2rem;
    margin: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
    color: #ffffff;
}

.pros-cons-column {
    flex: 1; /* Each column takes up equal space */
    padding: 1rem;
    border-radius: 0.5rem;
    transition: all 0.5s ease; /* Smooth transition for hover effects */
}

.pros-cons-column h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #00ffb7; /* Cyan color for headlines */
}

.pros-cons-column ul {
    list-style-type: disc;
    padding-left: 1.5rem;
}

.pros-cons-column li {
    margin-bottom: 0.5rem;
    line-height: 1.5;
}

/* Updated hover effect */
.pros-and-cons .pros-cons-column {
    opacity: 0.7; /* Slightly dim both columns by default */
}

.pros-cons-column:hover {
    opacity: 1; /* Brighten the hovered column */
    transform: scale(1.03); /* Slightly enlarge the hovered column */
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.7); /* Add more depth to the hovered column */
}

/* Ensure only the non-hovered column is dimmed */
.pros-and-cons .pros-cons-column:hover ~ .pros-cons-column {
    opacity: 0.5; /* Dim the non-hovered column */
}

/* Mobile Optimization: Stack columns vertically */
@media (max-width: 768px) {
    .pros-and-cons {
        flex-direction: column;
        gap: 1rem; /* Reduced gap for vertical stacking */
    }

    .pros-cons-column {
        margin-bottom: 1rem;
    }
}