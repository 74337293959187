/* ImageRotator.css */

.image-rotator {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 900px; /* Adjust based on your needs */
  overflow: hidden; /* To hide any overflow */
}

.rotating-image {
  max-width: fit-content;
  max-height: 600px;
  border-radius: 8px; /* Optional: Adds rounded corners */
  transition: opacity 0.5s ease-in-out; /* Smooth transition between images */
}