/* src/components/ExcitingLink/ExcitingLink.css */
.exciting-link {
  background-color: #03152699; /* Dark background */
  padding: 2rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5); /* Shadow for depth */
  transition: all 0.3s ease; /* Smooth transition */
  color: #ffffff;
}

.exciting-link-headline {
  font-size: 2rem;
  color: #ff5500d7; /* Cyan for headlines */
  margin-bottom: 1rem;
}

.exciting-link-subheadline {
  font-size: 1.2rem;
  color: #d4b1b0;
  margin-bottom: 1.5rem;
}

.exciting-link-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #ff5e00bb; /* Button background */
  color: #ffffff;
  border: none;
  border-radius: 0.5rem;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.exciting-link-button:hover {
  background-color: #ff8000; /* Darker on hover */
}  