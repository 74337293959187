/* ImageText.css */

/* Default theme (Cyan) */
.image-text.default {
    background: #00000078; /* Dark background color */
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    margin: 2rem 0 2rem 0px;
  }
  
  .image-text.default .headline {
    font-size: 1.5rem;
    margin: 10px 0 10px;
    font-weight: 600;
    color: #ffffff; /* Cyan color for emphasis */
    text-align: center;
  }
  
  .image-text.default .content-container {
    display: grid;
    background: linear-gradient(25deg, #216bfeca, #29ade6a1, #0eecd2ca); /* Darker Cyan/Violet Gradient */
    grid-template-columns: 1fr 2fr; /* 1/3 image, 2/3 text */
    gap: 20px;
    align-items: center;
    border-radius: 0px 0px 10px 10px;
  }
  
  .image-container {
    width: 100%;
    border-radius: 0px 0px 0px 10px;
  }
  
  .image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
    margin: 10px;
  }
  
  .text-container {
    padding: 20px;
    color: #ffffff;
  }
  
  .image-text.default .text p {
    margin: 10px 0;
    color: #c9e0ee;
    line-height: 1.6;
  }
  
  .image-text.default .text strong {
    font-weight: 700;
    color: #ffffff;
  }
  
  .image-text.default .text em {
    font-style: italic;
    color: #ffffff;
  }
  
  .image-text.default .text ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 10px 0;
  }
  
  .image-text.default .text li {
    margin: 5px 0;
    color: #b0c7d4;
  }
  
  /* Dark-Red Theme */
  .image-text.dark-red {
    background: linear-gradient(25deg, #03152699, #000000, #03152699, #000000, #03152699);; /* Dark red background */
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    margin: 2rem 0 2rem 0px;
  }
  
  .image-text.dark-red .headline {
    font-size: 1.5rem;
    margin: 1rem 1rem;
    font-weight: 600;
    color: #e6a739; /* Bright red for emphasis */
    text-align: center;
  }
  
  .image-text.dark-red .content-container {
    display: grid;
    grid-template-columns: 1fr 2fr; /* 1/3 image, 2/3 text */
    gap: 0;
    align-items: center;
    border-radius: 0px 0px 10px 10px;
  }

  .image-text.dark-red .image-container {
    width: 100%; /* Ensure it takes full width of the grid column */
    padding: 0; /* Remove padding to avoid shifts */
    margin: 0; /* Ensure no extra margins are pushing it */
  }
  
  /* Responsive Design: Switch to vertical layout on mobile screens */
  @media (max-width: 768px) {
    .image {
      width: 0%;
    }

    .image-text.default .content-container {
      display:block;
      background: linear-gradient(25deg, #216bfeca, #29ade6a1, #0eecd2ca); /* Darker Cyan/Violet Gradient */
    }  
      
    .image-text.dark-red .content-container {
      display: flex;
      flex-direction: column; /* Stack image and text vertically */
    }
  
    .image-text.dark-red .image-container {
      border-radius: 10px 10px 0 0; /* Adjust border radius for stacked layout */
      max-width: 90%;
    }

    .image-text.dark-red .image {
        padding-top: 1rem;
        width: 100%; /* Make sure image scales correctly within the container */
        height: auto; /* Maintain aspect ratio */
        display: block; /* Remove any inline display gaps */
        margin: 0; /* Ensure no margins */
      }
      
  
    .image-text.dark-red .text-container {
      padding: 15px; /* Adjust padding for a tighter fit on mobile */
    }
  }
  
  .image-text.dark-red .text p {
    margin: 10px 0;
    color: #f5f1c6;
    line-height: 1.6;
  }
  
  .image-text.dark-red .text strong {
    font-weight: 700;
    color: #fffacc;
  }
  
  .image-text.dark-red .text em {
    font-style: italic;
    color: #fff7cc;
  }
  
  .image-text.dark-red .text ul {
    list-style-type: disc;
    margin: 10px 0;
  }
  
  .image-text.dark-red .text li {
    color: #f5efb1;
  }
  