/* ReachMe.css */
.reach-me {
    text-align: center;
    padding: 20px;
    background-color: #16333493; /* Dark background color */
    color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    margin: 20px 0;
}

.reach-me-headline {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #00ffd5; /* Cyan color for emphasis */
}

.reach-me-text {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #b0c7d4; /* Light cyan/grey text color */
}

.contact-details {
    display: flex;
    justify-content: center;
    gap: 20px; /* Space between contact details */
    flex-wrap: wrap; /* Ensure it wraps if there are many contact details */
}