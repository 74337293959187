/* HeroText.css */
.hero-text-container {
    text-align: center; /* Center the text */
    padding: 50px 20px; /* Add padding for spacing */
    margin: 5px 0px;
    color: #ffffff; /* White text for contrast */
    border-radius: 10px; /* Rounded corners for a modern look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Soft shadow for depth */
    background-color: #0048ff20;
}

.hero-headline {
    font-size: 2.5rem; /* Large size for headline */
    margin: 0;
    font-weight: 700; /* Bold font weight */
    color: #00e5ff; /* Cyan color for headline */
}

.hero-subheadline {
    font-size: 1.5rem; /* Medium size for subheadline */
    margin: 10px 0 0; /* Spacing below the headline */
    font-weight: 400; /* Regular font weight */
    color: #b0c7d4; /* Lighter cyan/grey for subheadline */
}